<template>
  <v-card>
    <v-card-title>{{ $t('driving.steps.additionalInfo') }}</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" v-if="activeStep === 3">
            <validation-provider
              :rules="{
                required: [2, 3].includes(editedItem.driving_type_id) ? true : false,
                max: 10,
                regex: /^[A-Z,a-z]{1,3}\d{1,5}$/,
              }"
              v-slot="{ errors }"
              name="flight_number"
              class="d-flex align-center justify-space-between">
              <v-text-field
                v-model="editedItem.flight_number"
                clearable
                :label="$t('driving.flightNumber')"
                type="text"
                counter="10"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider rules="max:200" v-slot="{ errors }" name="waiting_board_text">
              <v-text-field
                v-model="editedItem.waiting_board_text"
                clearable
                :label="$t('driving.waitingBoardText')"
                type="text"
                counter="200"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              rules="numeric|max:10"
              v-slot="{ errors }"
              name="suitcases_number"
              class="d-flex align-center justify-space-between">
              <number-input-field
                v-model="editedItem.suitcase_number"
                :label="$t('driving.suitcasesNumber')"
                type="text"
                :disabled="formDisabled"
                :errors="errors"></number-input-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              rules="numeric|max:10"
              v-slot="{ errors }"
              name="passengers_number"
              class="d-flex align-center justify-space-between">
              <number-input-field
                v-model="editedItem.passenger_number"
                :label="$t('driving.passengersNumber')"
                type="text"
                :disabled="formDisabled"
                :errors="errors"></number-input-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              rules="numeric|max:10"
              v-slot="{ errors }"
              name="baby_seats_number"
              class="d-flex align-center justify-space-between">
              <number-input-field
                v-model="editedItem.baby_seat_number"
                :label="$t('driving.babySeatsNumber')"
                type="text"
                :disabled="formDisabled"
                :errors="errors"></number-input-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              rules="numeric|max:10"
              v-slot="{ errors }"
              name="stops_number"
              class="d-flex align-center justify-space-between">
              <number-input-field
                v-model="editedItem.stop_number"
                :label="$t('driving.stopsNumber')"
                type="text"
                :disabled="formDisabled"
                :errors="errors"></number-input-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6" v-if="!clientIdHidden">
            <validation-provider rules="max:200" v-slot="{ errors }" name="client_id">
              <v-text-field
                v-model="editedItem.client_id"
                clearable
                :label="$t('driving.clientId')"
                type="text"
                counter="200"
                :disabled="formDisabled"
                :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox v-model="editedItem.wheelchair">
              <template v-slot:label>
                <span>
                  <v-icon left>mdi-wheelchair-accessibility</v-icon>
                  {{ $t('driving.accessibleForWheelchair') }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
              <v-textarea
                v-model="editedItem.note"
                clearable
                counter="1000"
                :label="$t('driving.note')"
                rows="2"
                auto-grow
                :disabled="formDisabled"
                :error-messages="errors"></v-textarea>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <add-files
              :editedItem="editedItem"
              :attachments="editedItem.attachments"
              :filesToSend="filesToSend"
              deleteRoute="drivings/deleteDrivingAttachment"
              @addNewFile="(file) => $emit('addNewFile', file)"
              @deleteNewFile="(index) => $emit('deleteNewFile', index)"></add-files>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="failed || formDisabled" color="primary cancel" @click="$emit('prevStep')">{{
        $t('buttons.prev')
      }}</v-btn>
      <v-spacer />
      <button-submit :failed="failed || formDisabled" buttonText="buttons.next"></button-submit>
    </v-card-actions>
  </v-card>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import AddFiles from '@/components/AddFiles.vue';
import NumberInputField from '@/components/input-fields/NumberInputField.vue';

export default {
  name: 'AdditionalInfo',
  props: ['editedItem', 'filesToSend', 'loading', 'failed', 'formDisabled', 'activeStep', 'clientIdHidden'],
  components: {
    ButtonSubmit,
    AddFiles,
    NumberInputField,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {},
};
</script>
